.mt-50 {
    margin-top: 50px;
}

.api-card {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.api-card-source {
    background-color: #336fd1;
    color: white;
    padding: 3px;
    border-radius: 10px;
    text-align: center;
    margin-left: 16px;
}

.api-card-source-title {
    font-weight: 500;
    color: white;
    margin: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-family: "Roboto Mono", sans-serif !important;
}

.api-card-source-status-ok {
    font-weight: 500;
    color: #51a451;
    margin: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.api-card-source-status-err {
    font-weight: 500;
    color: #ea5a4b;
    margin: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.api-card-source-status-err {
    font-weight: 500;
    color: #585858;
    margin: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-family: "Roboto Mono", sans-serif !important;
}

.api-card-list {
    margin-left: 20px;
    margin-top: 20px;
}
.api-card-list-txt {
    display: inline;
    margin-left: 10px;
}
.api-card-list-img {
    display: inline;
}
