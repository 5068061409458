.spot-container {
    background-color: #f5f7f9;
    padding: 4px;
    margin: 6px;
    border-bottom: 1px solid rgb(194, 194, 194);
}

.spot-container:hover {
    background-color: #e3f2ff !important;
}

.spot-container-p {
    margin: 0px;
}

.Name {
    color: rgb(87, 87, 87);
    font-weight: 200 !important;
    margin: 0px;
}

.Type {
    color: #336fd1;
    font-family: "Roboto Mono", sans-serif !important;
    margin: 0px;
}

.api-card-source-sam {
    background-color: #ea5a4b;
    color: white;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.api-card-source-title {
    color: white;
    margin: 0px;
    font-family: "Roboto Mono", sans-serif !important;
}
