.BgColor {
    background-color: #4aadf2;
    border-radius: 10px;
    padding: 20px;
}

.MainText {
    color: white;
    font-weight: 600;
}

.mt-100 {
    margin-top: 100px;
}

.ErrorLabel {
    color: red;
    text-align: center;
}
